import React, { useState, useEffect, useRef } from 'react';
import { Table, Form } from 'react-bootstrap';
import { tableSearch } from '../../Utils/searchFunctions';
import CTableFull from './CTableFull';
import { Link } from 'react-router-dom';
import { useAddToBackend } from '../../api/BackendRequests';
import CTableDownload from './CTableDownload';
import { format } from '../../Utils/formatFunctions';

function CTable(props) {
    const [ctable, setCtable] = useState([]);
    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState('asc'); // row order after sorting
    const prevSortOrder = useRef({});
    const data_classname = {
        decimal: 'text-end',
        currency: 'text-end'
    }

    // console.log(JSON.parse(props.config.definition));
    // console.log(props.data);

    // defino la mutation para solicitar el archivo al backend
    const { mutate, isPending, isSuccess, data, isError } = useAddToBackend('content_'+props.pathId);

    const config = JSON.parse(props.config.definition);
    const module_name = props.config.name;
    const column_config = config.table_fields.filter(item => {return item.properties.visible_on_summary === true && (item.properties.location === 'header' || item.properties.location === 'footer')});
    const searchable = config.table_fields.filter(item => {return item.properties.searchable === true});
    const visible_on_full = config.table_fields.filter(item => {return item.properties.visible_on_full === true});
    const CContact = config.component?.contact_form && require(`./CContact.js`).default;

    useEffect(() => {
        // creo el state para data agregando el campo drilldown al objeto
        if(config.component.expandable === true) {
            setCtable(props.data.map(item => ({...item, drilldown:'collapsed'})))
        } else {
            setCtable(props.data);
        }
    },[props.data, config.component.expandable])

    const multiFilter = (ctable_item) => {
        return tableSearch(ctable_item, searchable, search);
    };

    const handleSortingChange = (e, column) => {
        //console.log(e.target.id);
        
        prevSortOrder.current = {id: e.target.id, sortOrder: sortOrder};
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        setCtable([...ctable].sort((a,b) => {
            // handle the undefined column values
            let recordA = a[column] === undefined ? -999999999 : a[column];
            let recordB = b[column] === undefined ? -999999999 : b[column];

            //return recordA.toString().toLowerCase().localeCompare(recordB.toString().toLowerCase()) * (sortOrder === 'asc' ? 1 : -1);
            return isNaN(recordA) ? 
                recordA.toString().toLowerCase().localeCompare(recordB.toString().toLowerCase()) * (sortOrder === 'asc' ? 1 : -1)
            :
                (recordA-recordB) * (sortOrder === 'asc' ? 1 : -1);
        }));
    };

    const handleDrilldown = (id, drilldown) => {
        const drilldown_state = drilldown === 'collapsed' ? 'expanded' : 'collapsed';
        setCtable(ctable.map(item => ({...(item.id === id || item.Id === id) ? {...item, drilldown: drilldown_state} : item})));
    };

    const handleDownload = async (endpoint) => {
        mutate({route: '/api/content/module/'+props.config.module_id, jsonArray: {endpoint, content_id: props.config.content_id}, method: 'post'},{
            onSuccess: (result) => {
                const link = document.createElement("a");
                link.href = `data:application/pdf;base64,${result.file}`;
                link.setAttribute('download', 'file_'+Date.now()+'.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        });
    };

//console.log(format(ctable[2]?.Outstanding_amount__c,{data_type: 'text'}));

    return <>
    <div className="app-content">
        <h3>{config.component.title}</h3>
        <div className="py-3">
            <Form>
                <Form.Control 
                    onChange = {e => setSearch(e.target.value.toLowerCase())}
                    placeholder='Search box'
                />
            </Form>
        </div>

        <Table>
            <thead>
                <tr>
                    {column_config.map((item,index) => (
                        <th key={config.component.id+item.api_name} id={config.component.id+index} onClick={e => handleSortingChange(e,item.api_name)} 
                        className={`sortable ${prevSortOrder.current.id === config.component.id+index ? prevSortOrder.current.sortOrder : ''} ${data_classname[item.properties.data_type]}`}>
                            {item.label}
                        </th>
                    ))}
                    {config.component.downloadable && <th key={config.component.id+'download'}></th>}
                </tr>
            </thead>
            <tbody>
                {ctable.filter(item => multiFilter(item))
                .map((record,index) => {
                    let tr = <tr key={config.component.id+'_'+index}>
                        {column_config.map((field,k) => (
                            <td key={k} className={`${data_classname[field.properties.data_type]} ${record.drilldown === 'expanded' ? 'no-bottom-border' : null}`}>
                                {k === 0 && config.component.expandable === true ? 
                                    <Link onClick={()=>handleDrilldown((record.id || record.Id), record.drilldown)}>
                                        <div style={{textTransform: 'capitalize'}} className={k === 0 ? record.drilldown : null}>
                                            {format(record[field.api_name],{locale: config.component.locale, digits: field.properties.digits, data_type: field.properties.data_type, currency: config.component.currency})}
                                        </div>
                                    </Link>
                                :
                                    <div style={{textTransform: 'capitalize'}} className={data_classname[field.properties.data_type]}>
                                        {format(record[field.api_name],{locale: config.component.locale, data_type: field.properties.data_type, currency: config.component.currency})}
                                    </div>
                                }
                            </td>
                        ))}

                        {/* Download-record column */}
                        {config.component.downloadable && <td key={config.component.id+'download'+index} className={`${record.drilldown === 'expanded' ? 'no-bottom-border' : null} text-end`}>
                            {record.download_endpoint__c && <CTableDownload data={record.download_endpoint__c} clickHandler={handleDownload} />}
                        </td>}
                    </tr>

                    let drilldown = <tr key={config.component.id+'_full_'+index}>
                        <td colSpan="12">
                            <div className='d-flex justify-content-center'>
                                <div className='col-11'>
                                    <CTableFull config={visible_on_full} locale={config.component.locale} currency={config.component.currency} data={record} />
                                </div>
                            </div>

                            {config.component?.contact_form && 
                            <div className='d-flex justify-content-center'>
                                <div className='col-11'>
                                    <CContact 
                                        config={{...config.contact_form, locale:config.component.locale, currency: config.component.currency}} 
                                        data={record} 
                                        //name={module_name}
                                    />
                                </div>
                            </div>}
                        </td>
                    </tr>

                    return <React.Fragment key={config.component.id+'_fragment_'+index}>
                        {tr}
                        {record.drilldown === 'expanded' && drilldown}
                    </React.Fragment>;
                })}
            </tbody>
        </Table>
    </div>
    </>
}

export default CTable;