import React from 'react';
import { useBackendData } from '../../api/BackendRequests';
import { useParams, useLocation } from 'react-router-dom';
import Spinner from '../../Components/html/Spinner';
import Error from '../../Pages/Error';
import { getCookie } from "../../Utils/cookieHandler";

/**
 * This page contains 4 components: 
 * Component1: top full wide.
 * Component2: below Component1, left side.
 * Component3: below Component1, right side.
 * Component4: below Components 2 and 3.
 * The component where the data will go is determined by the records on the Content table.
 * @returns Returns the html content for every module page.
 */
function Content(){
    const { id } = useParams();
    const location = useLocation();
    //const moduleTitle = getCookie('myclient_sid').menu.find(item => {return item.to === location.pathname}).label;

    const { isLoading, isSuccess, isError, error, data } = useBackendData('content_'+id,'/api/content/module/'+id, null, null, {retry: 0});

    if(isLoading) {
        return <Spinner spin={{size: 10, message:'connecting'}} />
    }

    if(isError) {
        return <Error message={error.message} />
    }

    /*
    let Components = {};
    let text;
    */
    if(isSuccess) {
        // console.log(data);
        // console.log(data.config);

        /* código para cambiar los keys a minúscula. No se usará. */
        /*
        for(const key in data.data) {
            comp_data[key] = data.data[key].map((component) => {
                const newObj = Object.keys(component).reduce((destination, key) => {
                    //destination[key.toLowerCase()] = component[key];
                    if(key === 'Id') {
                        destination[key.toLowerCase()] = component[key];
                    }
                    return destination;
                }, {});
                return newObj;
            });
        }
        console.log(comp_data);
        */

        /*
        for (const key in data.config) {
            if(!data.config[key]) {
                continue;
            }

            Components[key] = require(`./${data.config[key].component_name}`).default;
            //text += `<div className="row">{<Components[${key}] config={data.config[${key}]} data={data.data[${key}]} />}</div>`
            //text = (<div className="row"><Components[key] /></div>);
        }
        */
    }

    const Component1 = data.config.Component1 ? require(`./${data.config.Component1.component_name}`).default : null;
    const Component2 = data.config.Component2 ? require(`./${data.config.Component2.component_name}`).default : null;
    const Component3 = data.config.Component3 ? require(`./${data.config.Component3.component_name}`).default : null;
    const Component4 = data.config.Component4 ? require(`./${data.config.Component4.component_name}`).default : null;

    return <div>

        {/* data.data es un array con hasta 4 objetos (component 1...4). Implementar algo como un find(component1) en data
        y si lo encuentra entonces que render en este div el component_name y le pase en los props los datos. Es decir,
        data deberá traer cada component_location como entrada al array, luego la info del component que se debe cargar,
        luego la definición de layout del component (e.g. para CTable debe traer la definición de columnas, tipo de 
        dato de cada columna, etc.) y finalmente los datos a mostrar. */}
        {/* 
        data = [
            'component1': {
                'component_name': 'CTable',
                'component_definitions': {
                    'columns': ...,
                    'order_by': ...
                },
                component_data: {
                    ... los datos que deben coincidir con las definitions anteriores ...
                }
            },
            'component4': {}
        ];
        */}

        {/* Component 1 */}
        { /* pathId is required to differentiate the queryKey (useQuery) and key is used to reset the state for every component that is rendered */ }
        <div className="row pt-2">{Component1 && <Component1 config={data.config.Component1} data={data.data.Component1} pathId={id} key={id} />}</div>

        {/* Components 2 and 3 */}
        <div className="row">
            <div className="col-sm-6">{Component2 && <Component2 config={data.config.Component2} data={data.data.Component2} pathId={id} key={id} />}</div>
            <div className="col-sm-6">{Component3 && <Component3 config={data.config.Component3} data={data.data.Component3} pathId={id} key={id} />}</div>
        </div>

        {/* Component 4 */}
        <div className="row">{Component4 && <Component4 config={data.config.Component4} data={data.data.Component4} pathId={id} key={id} />}</div>
    </div>
}

export default Content;