import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../Components/html/Button";
import del_icon from "../../images/red-delete.svg";
import { Link } from "react-router-dom";
import { useAddToBackend } from '../../api/BackendRequests';
import { format } from '../../Utils/formatFunctions';

const CContact = (props) => {
    const { t } = useTranslation('ccontact');
    // const STATUS_IDLE = 0;
    // const STATUS_UPLOADING = 1;

    const [files, setFiles] = useState([]);
    //const [status, setStatus] = useState(STATUS_IDLE);
    const [errorMessage, setErrorMessage] = useState();
    const [message, setMessage] = useState();
    const inputFile = useRef(null);

    const { mutate, isError, isPending, isSuccess, error } = useAddToBackend('contact');

    if(isError) {
        console.log(error.message);
    }

    const uploadFiles = (data) => {
        mutate({route: '/api/content/contact', jsonArray: data, method: 'post', type: 'file'}, {
            onSuccess: (result) => {
                //setStatus(STATUS_IDLE);
                setMessage('');
                inputFile.current.value = '';
                setFiles([]);
            }
        });
    }

    const packFiles = (files)=> {
        // use FormData to pack the textarea and files
        const data = new FormData();

        // append the files
        [...files].forEach((file, i) => {
            data.append(`file-${i}`, file, file.name)
        });
        //console.log(data.get('file-0'));

        // add value property to header_fields (coming from config json) and format each field accordingly
        let headerFields = props.config.header_fields;
        headerFields = headerFields.map(field => {
            field.value = format(props.data[field.api_name],{locale: props.config.locale, data_type: field.data_type, currency: props.config.currency});
            delete field['api_name'];
            return field;
        });
        //console.log(props.config);

        // append the data before sending it to backend
        const emailData = {
            //module_name: props.name,
            header_fields: headerFields,
            message: message === undefined ? t('no_message') : message?.replace(/\n/g, "<br />") // replace line breaks from textarea
        };
        data.append('email_data', JSON.stringify(emailData));

        return data;
    }

    const handleUploadClick = () => {
        if (files.length !== 0 || (message !== undefined && message !== '')) {
            const data = packFiles(files);
            uploadFiles(data);
        }
    }

    const handleRemoveClick = (i,f) => {
        //console.log(inputFile.current.files);

        // remove the item from the input FileList. It must be done this way since the input.files object is read-only
        const dt = new DataTransfer();
        for(let m = 0; m < inputFile.current.files.length; m++){
            if(m !== i) {
                dt.items.add(inputFile.current.files[m]);
            }
        }
        inputFile.current.files = dt.files;

        // update files state with the new object
        setFiles(inputFile.current.files);
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const handleFileChange = (e) => {
        setErrorMessage("");
        setFiles(e.target.files);

        if(inputFile.current.files.length > 5) {
            // check that max. 5 files are selected
            inputFile.current.value = "";
            setFiles([]);
            setErrorMessage(t('error_count'));
        } else {
            // check that every file is smaller than 1MB
            for(let i = 0; i < inputFile.current.files.length; i++) {
                if(inputFile.current.files[i].size/1000 > 1024) {
                    inputFile.current.value = "";
                    setFiles([]);
                    setErrorMessage(t('error_size'));
                    break;
                }
            }
        }
    }

    const renderError = () => (
        errorMessage !== "" && <small className="text-danger my-1">{errorMessage}</small>
    )

    const renderFileList = () => (<ul className="list-group list-group-flush my-1">
        {[...files].map((f, i) => (
            <li key={i} className="list-group-item small py-1">
                <div className="row">
                    <div className="col-11">{f.name}</div>
                    <div className="col-1"><Link onClick={() => handleRemoveClick(i,f)}><img alt="remove" src={del_icon} width={17} /></Link></div>
                </div>
            </li>
        ))}
    </ul>);

    const getButtonStatusText = () => (
        !isPending ? t('send_form') : t('sending_form')
    );

    return <>
    <div>
        <div className="card bg-light mt-2 mb-4">
            <div className="card-body ctable-full">
                <b>{props.config.title}</b>
                <p className="">{props.config.message}</p>

                <div className="row">
                    {props.config.upload_text && 
                    <div className='col-7'>
                        <textarea type="text" name="client_message" rows="4" className="form-control form-control-sm mb-2"
                            placeholder={props.config.textarea_placeholder} 
                            value={message}
                            onChange={handleMessageChange}
                        />
                    </div>
                    }

                    {props.config.upload_files && 
                    <div className='col-5'>
                        <input type="file" name="file" multiple 
                            onChange={(e) => handleFileChange(e)} 
                            className="form-control form-control-sm" 
                            ref={inputFile}
                            accept="application/pdf, image/jpeg, image/png, image/jpg"
                        />
                        {renderFileList()}
                        {renderError()}
                    </div>
                    }
                    {!isSuccess && 
                    <div className='col-12 mt-1'>
                        <Button className="btn btn-primary btn-sm" onClick={handleUploadClick} isPending={isPending}>{getButtonStatusText()}</Button>
                    </div>
                    }
                    {isSuccess && <div className='col-12 mt-1'><div className={`col-6 alert alert-success`} role="alert">{t('successful')}</div></div>}
                </div>
            </div>
        </div>
    </div>
    </>
}

export default CContact;