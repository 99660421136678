import React from 'react';
import EditClient from './EditClient';
import Users from '../../Pages/Users';
import Accordion from 'react-bootstrap/Accordion';
import { useOutletContext } from 'react-router-dom';
//import Error from '../../Pages/Error';

function Client(){

    const [client] = useOutletContext();

    return <>
    <div className="row pb-3">
        <div className="col-sm-12">
            <h1>{client.name}</h1>
        </div>
    </div>

    <Accordion defaultActiveKey='0'>
        <Accordion.Item eventKey='0'>
            <Accordion.Header><h3>Edit information</h3></Accordion.Header>
            <Accordion.Body>
                <EditClient client={client} />
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey='1'>
            <Accordion.Header><h3>Manage users</h3></Accordion.Header>
            <Accordion.Body>
                <Users client_id={client.id} />
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    </>
}

export default Client;