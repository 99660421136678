import React, { useState } from 'react';
import { useAddToBackend } from '../../api/BackendRequests';
import { useQueryClient } from '@tanstack/react-query';
import TitleWithPill from '../../Components/html/TitleWithPill';
import ClientForm from './ClientForm';
import { useOutletContext, Navigate, useLocation } from 'react-router-dom';
import Error from '../../Pages/Error';
import Spinner from '../../Components/html/Spinner';
import { validateEmail } from '../../Utils/formatFunctions';

function CreateClient(){
    const { ...mutation } = useAddToBackend('clients');
    const [client] = useOutletContext();
    const queryClient = useQueryClient();
    const location = useLocation();
    const [errorMessage, setErrorMessage] = useState('');

    let count = client.activeLicenseCount - client.active;
    let activeClass = count <= 0 ? 'bg-danger' : 'bg-info';

    function onSubmit(client){
        const validEmails = validateEmail(client.contacts);

        // Check if the code already exists
        if(Object.values(queryClient.getQueryData(['clients']).results).filter(n => n.code === client.code).length > 0) {
            setErrorMessage('There is already a client with the same code registered on your company.');
        } else if(!validEmails.valid) {
            setErrorMessage('The email field is invalid. If adding multiple emails, make sure they are separated by semicolon ";"');
        } else {
            setErrorMessage('');

            // Concat emails with ";"
            client.contacts = validEmails.email.join(";");

            // Camelcase for name
            client.name = client.name.charAt(0).toUpperCase() + client.name.slice(1);

            // Defino el state con los datos del form (client) antes del mutation
            location.state = client;

            //console.log(client); return false;
            mutation.mutate({route: '/api/client/', jsonArray: client, method: 'post'});
        }
    }

    if(mutation.error) {
        return <Error message={mutation.error.message} />
    }

    if(mutation.isSuccess) {
        // Direcciono al nested route: /clients/new/id y agrego los parámetros al state
        return <Navigate to ={mutation.data?.insertId.toString()} state={{...location.state, id: mutation.data?.insertId}} />
    }

    if(mutation.isPending){
        return <Spinner spin={{size:10, message:'Creating the new client...'}} />
    }

    return <>
    <div className='row g-3'>
        <TitleWithPill class={activeClass} count={count}>Add new client</TitleWithPill>
    </div>

    <div className='row g-3'>
        <p>You are currently on a contract of <b>{client.activeLicenseCount}</b> licenses.{count <= 0 && <span> The maximum number of licenses has been reached.</span>}</p>
    </div>

    {errorMessage && 
    <div className="row g-3">
        <div className="col-sm-12">
            <div className="alert alert-danger col-lg-6" role="alert">{errorMessage}</div>
        </div>
    </div>}

    {count > 0 && <ClientForm name='' code='' isPending={mutation.isPending} onSubmit={onSubmit} /*create={true}*/ />}
    </>
}

export default CreateClient;