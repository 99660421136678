import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '../../Components/html/Button';

function ClientForm(props){
    
    let [name, setName] = useState(props.name);
    let [code, setCode] = useState(props.code);
    const [contacts, setContacts] = useState(props.contacts);

    /*
    let [name, setName] = useState('');
    let [code, setCode] = useState('');
    */

    function nameInputHandler(event){
        setName(event.target.value);
    }

    function codeInputHandler(event){
        setCode(event.target.value.replace(/\s/g,''));
    }

    function contactsInputHandler(event){
        setContacts(event.target.value.replace(/\s/g,''));
    }

    function onSubmit(event){
        event.preventDefault();
        props.onSubmit({name, code, contacts});
    }

    return <>
    <Form onSubmit={onSubmit}>
        <Form.Group className='mb-3' as={Row}>
            <Col lg="4">
                <Form.Group className='mb-2'>
                    {/* {!props.create && <Form.Label>Name</Form.Label>} */}
                    <Form.Control type="text" placeholder='Name' onChange={nameInputHandler} value={name} required />
                </Form.Group>
            </Col>

            <Col lg="2">
                <Form.Group className='mb-2'>
                    {/* {!props.create && <Form.Label>Code</Form.Label>} */}
                    <Form.Control type="text" placeholder='Code' onChange={codeInputHandler} value={code} required />
                </Form.Group>
            </Col>

            <Col lg="4">
                <Form.Group className='mb-0'>
                    {/* {!props.create && <Form.Label>Contacts</Form.Label>} */}
                    <Form.Control as="textarea" rows={1} placeholder='Contact emails separated by a semicolon ";"' onChange={contactsInputHandler} value={contacts} required />
                </Form.Group>
            </Col>

            <Col lg="2" className='pb-2'>
                <Form.Group className='pt-2 pt-lg-0 pt-xl-0'>
                    <Button isPending={props.isPending}>Save</Button>
                </Form.Group>
            </Col>
        </Form.Group>
    </Form>
    </>
}

export default ClientForm;