import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import moment from 'moment';
import { useAddToBackend } from '../../api/BackendRequests';
import { useQueryClient } from '@tanstack/react-query';
import Spinner from '../../Components/html/Spinner';
import Error from './../../Pages/Error';
import ClientForm from './ClientForm';

function EditClient(props){
    const queryClient = useQueryClient();

    const { mutate, isPending, isSuccess, data, isError, error } = useAddToBackend('clients');

    function onSubmit(client){
        mutate({route: '/api/client/'+props.client.id, jsonArray: client, method: 'put'},
            {
                onSuccess: () => {
                    queryClient.invalidateQueries('clients');
                    return true;
                }
            }
        );
    }

    if(isPending) {
        <Spinner spin={{size:10}} />
    }

    if(isError){
        // if the mutation returns error
        return <Error message={error.message} />
    }

    return <>
    {isSuccess && data?.changedRows === 1 &&
    <Form.Group className='mb-3' as={Row}>
        <div className="row">
            <div className="col-sm-12">
                <div className="alert alert-success col-lg-6" role="alert">Changes have been saved successfully.</div>
            </div>
        </div>
    </Form.Group>
    }

    <ClientForm name={props.client.name} code={props.client.code} contacts={props.client.contacts} isPending={isPending} onSubmit={onSubmit} /*create={false}*/ />

    <small>
        Created by <b>{props.client.created_by}</b> on {moment(props.client.created_at).calendar()} <br />
        {props.client.updated_by && props.client.updated_at && <span>Last updated by <b>{props.client.updated_by}</b> on {moment(props.client.updated_at).calendar()}</span>}
    </small>
    </>
}

export default EditClient;